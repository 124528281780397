html{
  display: flex;
  justify-content: space-around;
  background-color: rgb(49, 49, 49);
}
body{
  background-color: rgb(49, 49, 49);
}
.links{
  text-decoration: none;
  font-size: 10px;
  color: white;
}
a:link, a:visited, a:hover, a:active{
  text-decoration: none;
  font-size: 10px;
  color: white;
}
#links{
  background-color:rgb(41, 190, 239);
}
#header{
  background-color: rgb(59, 59, 59);
}
.name{
  color: rgb(41, 190, 239)
}
#job-title{
  color:white;
}
#tech{
  font-size: 12px;
}
.caption{
  font-weight:lighter;
  font-size: 11px;
}
li{
  font-size: 12px;
}
#resume{
  position: relative;
  left:150px;
  background-color:rgb(255, 249, 227);
}